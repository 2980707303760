
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBTextArea,
  MDBInput,
  MDBModalTitle
} from 'mdb-react-ui-kit';
import styles from './Home.module.css';
import { useTheme } from '../../themes/ThemesContext';
  import Loading from '../../components/Loading/LodingPage'; 

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation();
  const [centredModal, setCentredModal] = useState(false);
  const { theme } = useTheme();
  const [loading, setLoading] = useState(true); // Add loading state here

  const toggleOpen = () => setCentredModal(!centredModal);


   // Form states
   const [fullName, setfullName] = useState('');
   const [companyName, setCompanyName] = useState('');
   const [address, setAddress] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [additionalInfo, setAdditionalInfo] = useState('');
   const [formErrors, setFormErrors] = useState({});

  const isRTL = i18n.language === 'ar';

  useEffect(() => {
    // Set loading to false after 1000 milliseconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);


  // Regex for validation
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const phoneRegex = /^[0-9]{10,15}$/; // 10 to 15 digits phone number

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const errors = {};
    if (!fullName) errors.fullName = 'full name is required';
    if (!address) errors.address = 'Address is required';
    if (!email || !emailRegex.test(email)) errors.email = 'Valid email is required';
    if (!phone || !phoneRegex.test(phone)) errors.phone = 'Valid phone number is required';

    setFormErrors(errors);

    // If there are errors, stop submission
    if (Object.keys(errors).length > 0) return;

    // Prepare data to send
    const formData = {
      full_name: fullName,
      address,
      email,
      phone,
      additional_information: additionalInfo,
    };

    try {
      // Submit the form data to Directus API
      const response = await fetch('https://admin.newsharq.com/items/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit data');
      }

      alert('Form submitted successfully!');
      // Reset the form
      setfullName('');
      setCompanyName('');
      setAddress('');
      setEmail('');
      setPhone('');
      setAdditionalInfo('');
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <>
       {loading ? (
        <Loading />
      ) : (
    <div  className={`text-center ${styles.all}  ${theme === 'light' ? styles.light : styles.dark}`} >
        

     <section className={`${styles.home}`}>
      {/* <Navbar className={`text-center ${styles.nav}  ${theme === 'light' ? styles.light : styles.dark}`}  changeLanguage={changeLanguage} /> */}

        <MDBContainer  className={` ${i18n.language === 'ar' ? styles.allRtl : ''} `}>

          <MDBRow className={`d-flex align-items-center justify-content-center`}>
            <MDBCol md="8" className={`${styles.contnet}` }>
              <h1>{t('home.title')}</h1>
              <p>{t('home.description')}</p>
              <MDBBtn className={`${styles.dwonloadBTN}`} onClick={toggleOpen}>{t('home.downloadButton')}</MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className={`text-center ${styles.services} ${i18n.language === 'ar' ? styles.allRtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
  <MDBContainer >
    <MDBRow className={`d-flex align-items-center justify-content-center ${isRTL ? 'flex-row-reverse' : ''}`}>
    <h1 className={styles.whyChooseTitle}>
        {i18n.language === 'ar' ? (
          <>
           <span>    {t('home.whyChooseTitle').split(' ').map((word, index, arr) => (
              <span key={index} className={index === arr.length - 2 ? styles.whyChooseText : index === arr.length - 1 ? styles.ridersText : ''}>
                {word}{' '}
              </span>
            ))}  ؟</span>
          
          </>
        ) : (
          <>
            {t('home.whyChooseTitle').split(' ').map((word, index, arr) => (
              <span key={index} className={index === arr.length - 2 ? styles.whyChooseText : index === arr.length - 1 ? styles.ridersText : ''}>
                {word}{' '}
              </span>
            ))}
            <span>?</span>
          </>
        )}
      </h1>
      
      <img src={require('../../assets/images/icons/output-onlinepngtools.png')} alt="Riders underline" className={`${styles.vectorImage}`} />
      <MDBCol md="12" lg="4" sm="12" className={`${styles.serviceCol}`}>
        <div>
          <div className={`${styles.IconBody}`}>
          <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4c-1.293 0-2.395.844-2.813 2H12c-2.746 0-5 2.254-5 5h2a3 3 0 0 1 3-3h1v2.469c-.32.238-.734.578-1.219 1.062C10.953 12.36 10 13.5 10 15v13h12V15c0-1.5-.953-2.64-1.781-3.469A11 11 0 0 0 19 10.47v-.281l4.844.812l1.156.188V4.811L23.844 5l-5.094.844C18.293 4.77 17.234 4 16 4m0 2c.555 0 1 .445 1 1v3h-2V7c0-.555.445-1 1-1m7 1.188v1.625l-4-.688v-.25zM14.375 12h3.25c.152.105.578.39 1.156.969C19.453 13.64 20 14.5 20 15v11h-8V15c0-.5.547-1.36 1.219-2.031c.578-.578 1.004-.864 1.156-.969M14 17v2h4v-2z"></path></svg>
          </div>
          <h5>{t('home.service-H5-1')}</h5>
          <p>{t('home.service-p-1')}</p>
        </div>
      </MDBCol>
      <MDBCol  md="12" lg="4" sm="12" className={`${styles.serviceCol}`}>
        <div>
          <div className={`${styles.IconBody}`}>
          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32"><path fill="currentColor" d="M23 23v3H8.5a4.5 4.5 0 0 1 0-9H9v-2h-.5a6.5 6.5 0 0 0 0 13H23v3h8v-8Zm6 6h-4v-4h4Z"></path><path fill="currentColor" d="M21 22h-2v-3h-6v3h-2v-3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2zm-5-6a3 3 0 1 1 3-3a3.003 3.003 0 0 1-3 3m0-4a1 1 0 1 0 1 1a1 1 0 0 0-1-1"></path><path fill="currentColor" d="M23.5 4H9V1H1v8h8V6h14.5a4.5 4.5 0 0 1 0 9H23v2h.5a6.5 6.5 0 0 0 0-13M7 7H3V3h4Z"></path></svg>
          </div>
          <h5>{t('home.service-H5-2')}</h5>
          <p>{t('home.service-p-2')}</p>
        </div>
      </MDBCol>
      <MDBCol md="12" lg="4" sm="12" className={`${styles.serviceCol}`}>
        <div>
          <div className={`${styles.IconBody}`}>
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32"><path fill="currentColor" d="M5.822 2a3 3 0 0 0-2.998 2.898l-.752 22A3 3 0 0 0 5.07 30h3.861a3 3 0 0 0 2.999-3.102l-.753-22A3 3 0 0 0 8.18 2zm-.999 2.966a1 1 0 0 1 1-.966h2.356a1 1 0 0 1 1 .966l.752 22a1 1 0 0 1-1 1.034h-3.86a1 1 0 0 1-1-1.034zM11.577 30h14.924a3.5 3.5 0 0 0 3.5-3.5V7a1 1 0 0 0-1.707-.707L21 13.586V7a1 1 0 0 0-1.707-.707l-6.835 6.834l.094 2.735L19 9.414V16a1 1 0 0 0 1.707.707L28 9.414V26.5a1.5 1.5 0 0 1-1.5 1.5H26v-7.5a1.5 1.5 0 0 0-1.5-1.5h-8a1.5 1.5 0 0 0-1.5 1.5V28h-2.196a4 4 0 0 1-1.228 2M17 28v-7h7v7z"></path></svg>          </div>
          <h5>{t('home.service-H5-3')}</h5>
          <p>{t('home.service-p-3')}</p>
        </div>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</section>


<section className={`text-center ${styles.about}   ${theme === 'light' ? styles.light : styles.dark}`}>
  <MDBContainer   className={styles.aboutContent}>
    {/* First Row */}
    <MDBRow className="d-flex align-items-center justify-content-center mb-5">
      <MDBCol md="6" sm="12" >
        <img
          src={require('../../assets/images/LandingPage/home.webp')}
          alt="Placeholder"
          className="img-fluid border border-2 border-info"
        />
      </MDBCol>
      <MDBCol md="6" sm="12">
        <h2>{t('section1.title')}</h2>
        <p>{t('section1.content')}</p>
      </MDBCol>
    </MDBRow>
<hr className='my-5' style={{color:"#3190bc" }}/>
    {/* Second Row */}
    <MDBRow className="d-flex align-items-center justify-content-center mb-5">
      <MDBCol md="6" sm="12">
        <h2>{t('section2.title')}</h2>
        <p>{t('section2.content')}</p>
      </MDBCol>
      <MDBCol md="6" sm="12">
        <img
          src={require('../../assets/images/LandingPage/about2.webp')}
          alt="Placeholder"
          className="img-fluid  border border-2 border-info"
        />
      </MDBCol>
    </MDBRow>
    <hr className='my-5' style={{color:"#3190bc"}} />

    {/* Third Row */}
    <MDBRow className="d-flex align-items-center justify-content-center mb-5">
      <MDBCol md="6" sm="12" >
        <img
          src={require('../../assets/images/LandingPage/about3.webp')}
          alt="Placeholder"
          className="img-fluid  border border-2 border-info"
        />
      </MDBCol>
      <MDBCol md="6" sm="12">
        <h2>{t('section3.title')}</h2>
        <p>{t('section3.content')}</p>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</section>




      <section   className={`text-center ${styles.downloadSection} ${i18n.language === 'ar' ? styles.allRtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
        <div className={`${styles.downloadContent}`}>
          <MDBRow>
            <MDBCol>
              <MDBBtn className={`${styles.dwonloadBTN}`} onClick={toggleOpen}>{t('home.downloadButton')}</MDBBtn>
              <p>{t('home.appDescription')}</p>
            </MDBCol>
          </MDBRow>
        </div>
      </section>

     

<MDBModal className='mt-5' tabIndex="-1" open={centredModal} onClose={() => setCentredModal(false)}>
            <MDBModalDialog centered size='lg' className={`${styles.MDBModalDialog}`}>
              <MDBModalContent>
                <MDBModalHeader >
                <MDBModalTitle>{t('modal.modeltitle')}</MDBModalTitle>

                  <MDBBtn className="btn-close" color="danger" onClick={toggleOpen}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <form className={styles.form} onSubmit={handleSubmit}>
                    {/* full Name */}
                   
                        <MDBInput
                          label={t('modal.fullname')}
                          type="text"
                           className="mb-3"
                          value={fullName}
                          onChange={(e) => setfullName(e.target.value)}
                          required
                        />
                        {formErrors.fullName && <span className="text-danger">{formErrors.fullName}</span>}
                     

             

                    {/* Company Name */}
                    <MDBInput
                      label={t('modal.COMPANYNAME')}
                      type="text"
                      className="mb-3"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    />

                    {/* Address */}
                    <MDBInput
                      label={t('modal.addressOfCompany')}
                      type="text"
                      className="mb-3"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                    {formErrors.address && <span className="text-danger">{formErrors.address}</span>}

                    {/* Email */}
                    <MDBInput
                      label={t('modal.email')}
                      type="email"
                      className="mb-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {formErrors.email && <span className="text-danger">{formErrors.email}</span>}

                    {/* Phone */}
                    <MDBInput
                      label={t('modal.phone')}
                      type="tel"
                      className="mb-3"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    {formErrors.phone && <span className="text-danger">{formErrors.phone}</span>}

                    {/* Additional Information */}
                    <MDBTextArea
                      label={t('modal.Additional')}
                      rows="3"
                      className="mb-3"
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                    />

                    {/* Submit Button */}
                    <MDBBtn type="submit" className={styles.submitBtn}>
                      {t('modal.submit')}
                    </MDBBtn>
                  </form>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
    </div>
     )}
     </>
   );
 }
export default Home;

