import React, { useEffect } from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon ,
} from 'mdb-react-ui-kit';
import styles from './Footer.module.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';
  return (
    <div>
      {/* p-xl-5 */}
      <MDBFooter  className={`text-start text-lg-start text-light ${styles.Footer} ${isRTL ? styles.Frtl : ''}`} >
       
      <MDBContainer className='text-start text-md-start mt-5'>
          <MDBRow className={`mt-3 ${isRTL ? 'flex-row-reverse' : ''}`}>
          <MDBCol md="3" lg="3" xl="3" sm="6"    className={`mx-auto  ${styles['custom-cols']}`} >
            <h6 className=' mb-4'>{t('FooterContact')}</h6>
            <p className={styles.email}>
  <a href="mailto:info@riderscars.com?subject=Inquiry" className={styles.emailLink}>info@newsharq.com</a>
</p>

<p>
<Link target='_blank' to='https://wa.me/201222902903'>+2 01222902903
                  
                  </Link>

                 
</p>


<p>

<a href="tel:0244810025">+02 44810025</a>
                 
</p>

<p>
<Link target='_blank' to='https://www.facebook.com/profile.php?id=61562818595887'>
                    <MDBIcon size='lg' className={`me-3 ${styles['icon']}`} fab icon="facebook" />
                  </Link>

                
              
                  
                  <Link target='_blank' to='https://wa.me/201222902903'>
                    <MDBIcon size='lg' fab icon="whatsapp" />
                  </Link>
                  </p>
 
          </MDBCol>

            <MDBCol md="2" lg="2" xl="2"  sm="6"  className={`mx-auto mb-4 ${styles['custom-cols']}`} >
              <h6 className='mb-4'>
              
              {t('FooterExplorate')}
              </h6>
              <p>
              <Link to="/services">
              {t('nav1')}
              
              </Link>

              </p>
              <p>
              <Link to="/blog">
              {t('nav3')}
              
              </Link>

              </p>
             
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2"  sm="6"   className={`mx-auto mb-4 ${styles['custom-cols']}`}>
              <h6 className=' mb-4'> {t('Headquarter')}</h6>
              <p   className={` ${styles['HeadquarterPlac']}`}>
              {t('HeadquarterPlac')}
              
              </p>
           
            </MDBCol>

           
          </MDBRow>
        </MDBContainer>
      
     
    </MDBFooter>


   
    </div>
  )
}

// import React, { useEffect } from 'react'
// import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
// import styles from './Footer.module.css'
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// export default function Footer() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);


//     const { t, i18n } = useTranslation();
//     const isRTL = i18n.language === 'ar';
//   return (
//     <MDBFooter className={`text-light ${styles.Footer} ${isRTL ? styles.Frtl : ''}`}>
//       <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
//         <div className='me-5 d-none d-lg-block'>
//           <span>
//           {t('Getconnected')}
//           </span>
//         </div>

//         <div >
//         <p>
//  <Link target='_blank' to='https://www.facebook.com/profile.php?id=61562818595887'>
//                      <MDBIcon size='lg' className={`me-3 ${styles['icon']}`} fab icon="facebook" />
//                   </Link>

              
//                    <Link target='_blank' to='https://wa.me/201222902903'>
//                      <MDBIcon size='lg' fab icon="whatsapp" />
//                   </Link>
//                    </p>
//         </div>
//       </section>

//       <section className=''>
//         <MDBContainer className='text-center text-md-start mt-5'>
//           <MDBRow className='mt-3'>
//             <MDBCol md='4' lg='4' xl='3' className='mx-auto mb-4'>
//               <div className='d-flex justify-content-center algin-items-center'>
//               <img
//           src={require('../../assets/images/logo/LASTLOGO.png')}
//           alt="Placeholder"
//           width={100}
//           className="img-fluid "
//         /> 

// <h6 >

// {t('companyname')}
// </h6>
//               </div>
             
//               <p>
//                 Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit
//                 amet, consectetur adipisicing elit.
//               </p>
//             </MDBCol>

          

//             <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-4'>
//               <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Pricing
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Settings
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Orders
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Help
//                 </a>
//               </p>
//             </MDBCol>

//             <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
//               <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
//               <p>
//                 <MDBIcon color='secondary' icon='home' className='me-2' />
//                 New York, NY 10012, US
//               </p>
//               <p>
//                 <MDBIcon color='secondary' icon='envelope' className='me-3' />
//                 info@example.com
//               </p>
//               <p>
//                 <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
//               </p>
//               <p>
//                 <MDBIcon color='secondary' icon='print' className='me-3' /> + 01 234 567 89
//               </p>
//             </MDBCol>
//           </MDBRow>
//         </MDBContainer>
//       </section>

//       {/* <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
//         © 2024 Copyright : 
//         <a className='text-reset fw-bold' href='https://datac.com/'>
//           datac.com
//         </a>
//       </div> */}
//     </MDBFooter>
//   );
// }