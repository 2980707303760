

import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/ThemesContext';
import { MDBIcon } from 'mdb-react-ui-kit';
import styles from './Navbar.module.css';

const NavbarComponent = ({ changeLanguage }) => {
  const [open, setOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();
  const [logoSrc, setLogoSrc] = useState(require('../../assets/images/logo/logo2.png'));

  useEffect(() => {
    const updateLogoSrc = () => {
      setLogoSrc(theme === 'light' ? require('../../assets/images/logo/logo2.png') : require('../../assets/images/logo/output-onlinepngtools.png'));
    };

    updateLogoSrc();
  }, [theme]);

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    changeLanguage(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const closeNavbar = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const isArabic = i18n.language === 'ar';

  return (
    <div className={styles.contain}>
      <Navbar expand="lg" className={`${styles.navbar} ${theme === 'light' ? styles.light : styles.dark}`}>
        <Container>
     
          <Navbar.Toggle aria-controls="navbarCollapse" onClick={handleToggle}>
            <MDBIcon className={styles['barIcon']} icon={open ? 'times' : 'bars'} fas />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarCollapse" className={`${open ? styles.open : ''} ${styles['navbar-collapse']}`}>
            
          <div className={`d-flex m-5 me-0 ${styles['navbar-buttons']}`}>
              <div className={`${styles['navbar-item']}`}>
                <button className={`${styles['language-button']}`} onClick={toggleLanguage}>
                  {i18n.language === 'en' ? 'ع' : 'E'}
                </button>
              </div>
              <div className={` ${styles['navbar-item']}`}>
                <label className={`${styles['ui-switch']}`}>
                  <input type="checkbox" onChange={() => toggleTheme(theme === 'light' ? 'dark' : 'light')} checked={theme === 'dark'} />
                  <div className={styles['slider']}>
                    <div className={styles['circle']}></div>
                  </div>
                </label>
              </div>
            </div>
            <Nav className={`mx-auto ${styles.nav} ${isArabic ? styles.arabic : ''}`}>
              <div className={`${styles['navbar-item']}`}>
                <Link
                  className={`${styles['navbar-link']} ${location.pathname === '/' || location.pathname === '/Home' ? styles.active : ''}`}
                  to='/home'
                  onClick={closeNavbar}
                >
                  {t('nav2')}
                </Link>
              </div>
              <div className={`${styles['navbar-item']}`}>
                <Link
                  className={`${styles['navbar-link']} ${location.pathname === '/blog' ? styles.active : ''}`}
                  to='/blog'
                  onClick={closeNavbar}
                >
                  {t('nav3')}
                </Link>
              </div>

              <div className={`${styles['navbar-item']}`}>
                <Link
                  className={`${styles['navbar-link']} ${location.pathname === '/services' ? styles.active : ''}`}
                  to='/services'
                  onClick={closeNavbar}
                >
                  {t('nav1')}
                </Link>
              </div>
           
            </Nav>
          
          </Navbar.Collapse>



          <Navbar.Brand onClick={scrollToTop}>
                   <Link
                 
                  to='/home'
               
                >
            <img alt='logo' src={logoSrc} className={styles['navbar-brand']} />

                </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
