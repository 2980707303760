import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
} from 'mdb-react-ui-kit';
import styles from './Services.module.css';
import { useTheme } from '../../themes/ThemesContext';
import Loading from '../../components/Loading/LodingPage';

function Products() {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const isRTL = i18n.language === 'ar';

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const languageCode = i18n.language === 'ar' ? 'ar-SA' : 'en-US';
        const response = await fetch(
          `https://admin.newsharq.com/items/products?filter[status][_eq]=published&fields[]=*,translations.*&deep={"translations":{"_filter":{"languages_code":{"_eq":"${languageCode}"}}}}`
        );
        const data = await response.json();
        console.log('API Response:', data);

        const productsData = data.data.map((product) => {
          const translation = product.translations?.[0];
          return {
            ...product,
            translatedName: translation ? translation.name : product.name,
            price: translation ? translation.price : null,
          };
        });
        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [i18n.language]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark}`}
        >
          <section className={`${styles.productsSection}`}>
            <MDBContainer className={`${isRTL ? styles.allRtl : ''}`}>
              <MDBRow className="d-flex align-items-center justify-content-center mb-5">
                <MDBCol md="4" sm="12">
                  <img
                    src={require('../../assets/images/LandingPage/china.webp')}
                    alt="Placeholder"
                    className="img-fluid border border-2 border-info rounded my-3"
                  />
                </MDBCol>

                <MDBCol md="8" sm="12">
                  <h1 className={isRTL ? 'text-end' : 'text-start'}>
                    {t('productsSection.title')}
                  </h1>
                  <p className={`fs-5 ${isRTL ? 'text-end' : 'text-start'}`}>
                    {t('productsSection.content')}
                  </p>
                </MDBCol>
              </MDBRow>

              {['type1', 'type2', 'type3'].map((type) => {
                const productsOfType = products.filter(
                  (product) => product.type === type
                );

                if (productsOfType.length === 0) {
                  return null;
                }

                return (
                  <div key={type} className="mb-5">
                    <h1 className="mb-5">
                      {t(`types.${type}`)}
                      <hr className="m-auto mt-3" style={{ width: '50px' }} />
                    </h1>
                    <MDBRow>
                      {productsOfType.map((product) => (
                        <MDBCol key={product.id} md="3" sm="6" className="mb-4">
                          <MDBCard className={styles.productCard}>
                            
                            {product.price && (
                              <div className={styles.priceTag}>
                                {`${product.price} LE`}
                              </div>
                            )}
                            <MDBCardImage
                              src={`https://admin.newsharq.com/assets/${product.Product_image}`}
                              alt={product.translatedName}
                              position="top"
                              className={styles.productImage}
                            />
                            <MDBCardBody style={{ background: 'transparent' }} className={styles.productCardBody}>
                              <h2 className="card-title fs-5">{product.translatedName}</h2>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </div>
                );
              })}
            </MDBContainer>
          </section>
        </div>
      )}
    </>
  );
}

export default Products;
